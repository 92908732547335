<template>
    <div class="container">
        <div @click="toggle" :class="switcher.counterPost ? 'active' : 'not-active'">
            <div class="title">{{$t('counterRecharge.counter_post')}}</div>
            <p class="description">{{$t('counterRecharge.counter_post_desc')}}</p>
        </div>
        <div @click="toggle" :class="switcher.counterPre ? 'active' : 'not-active'">
            <div class="title">{{$t('counterRecharge.counter_pre')}}</div>
            <p class="description">{{$t('counterRecharge.counter_pre_desc')}}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            switcher: {
                counterPost: true,
                counterPre: false
            }
        }
    },
    methods: {
        toggle() {
            this.switcher = {
                counterPost: !this.switcher.counterPost,
                counterPre: !this.switcher.counterPre
            };
            this.$emit('change', this.switcher);
        }
    }
}
</script>

<style scoped>

.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    margin: 20px 0 0 0;
}

.title {
    color: #222F5A;
    font-weight: 700;
    font-size: 16px;
}

.description {
    color: #222F5A;
    font-size: 14px;
}

.not-active {
    background-color: transparent;
    border: solid 1px #ccc;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
}

.active {
    background-color:#2D7BF71A;
    border: 2px solid #2D7BF7;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
}
</style>
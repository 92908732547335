<template>
  <div
    v-if="addCounter"
    class="anime transform top-0 left-0 w-full h-full fixed"
    :class="addCounter ? 'translate-x-0' : '-translate-x-full'"
  >
    <div class="container mx-auto bg-white rounded p-6 w-10/12 sm:w-6/12 mt-12">
      <div class="flex justify-between items-center">
        <span class="text-2xl font-semibold" style="color: #201f1e">{{
          $t("meterDash.addBtn")
        }}</span>
        <Iconclose @click="passAddCounter" />
      </div>

      <div v-if="!valider">
        <kd-counter-switch @change="toggle" />
      </div>

      <form @submit.prevent="meterValidate" class="py-4" v-if="!valider">
        <div class="py-4">
          <label class="font-semibold" for="compId">{{
            isPostCounter
              ? $t("meterDash.subscriberNumb")
              : $t("meterDash.counterNumb")
          }}</label>
          <br />
          <input
            placeholder=""
            class="
              mt-2
              p-3
              h-minput
              border-1.5 border-kborder-500
              hover:border-kinput-3
              focus:border-kinput-2
              rounded
              w-full
              py-2
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="compId"
            name="compId"
            type="text"
            maxlength="20"
            v-model="counter.cNumb"
            v-validate="'required|numeric|max:20'"
            :class="{ ' border-tex-2': submitted && errors.has('compId') }"
          />

          <p v-show="submitted && errors.has('compId')" class="text-tex-2">
            {{ $t("counterRecharge.validate") }}
          </p>
          <p v-show="invalidMeter" class="text-tex-2">
            {{ $t("counterRecharge.invalidMeter") }}
          </p>
          <p v-show="blockedMeter" class="text-tex-2 text-sm">
            {{ $t("counterRecharge.blockedMeter") }}
          </p>
          <p v-show="noBills" class="text-tex-2 text-sm">
            {{ $t("counterRecharge.no_bills") }}
          </p>
        </div>

        <span class="mt-2 flex items-center">
          <button
            type="submit"
            class="
              text-white
              border
              h-search
              sm:h-minput
              bg-kbutton-1
              hover:bg-kbutton-2
              active:border active:border-kbutton-3
              px-4
              sm:px-6
              text-sm
              sm:text-base
              rounded
              focus:outline-none
            "
          >
            <LoaderCss class="ml-16" v-show="validRequest" />
            <span v-show="!validRequest">{{ $t("counterRecharge.btn") }}</span>
          </button>
          <button
            type="button"
            class="
              ml-4
              border
              text-kbutton-1
              border-kbutton-1
              h-search
              sm:h-minput
              hover:border-kbutton-2 hover:text-kbutton-2
              active:border active:border-kbutton-3
              py-1
              sm:py-2
              px-4
              sm:px-6
              text-sm
              sm:text-base
              rounded
              focus:outline-none
            "
            @click="passAddCounter"
          >
            {{ $t("homeDash.cancelBtn") }}
          </button>
        </span>
      </form>

      <form @submit.prevent="addMeter" class="py-4" v-if="valider">
        <div class="bg-gray-100 rounded border">
          <div class="m-3 p-1 flex flex-col">
            <span class="text-base">{{ counter.cUser }}</span>
            <div class="flex flex-row justify-between items-end">
              <div>
                <span class="text-xs text-gray-500">{{
                  isPostCounter
                    ? $t("meterDash.subscriberNumb")
                    : $t("meterDash.counterNumb")
                }}</span
                ><br />
                <span>{{ counter.cNumb }}</span>
              </div>
              <button
                type="button"
                class="
                  focus:outline-none
                  bg-white
                  text-compteur-600
                  hover:text-compteur-7
                  py-1
                  px-2
                  border border-compteur-600
                  hover:border-compteur-7
                  rounded
                "
                @click="valider = !valider"
              >
                {{ $t("rechargePay.btnModify") }}
              </button>
            </div>
          </div>
        </div>

        <div class="text-sm py-4">
          <label class="font-semibold sm:font-normal text-sm" for="family">{{
            $t("meterDash.family")
          }}</label>
          <br />
          <input
            placeholder="Ex: Maison"
            class="
              mt-2
              p-3
              h-minput
              border-1.5 border-kborder-500
              hover:border-kinput-3
              focus:border-kinput-2
              rounded
              w-full
              py-2
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="family"
            name="family"
            type="text"
            maxlength="20"
            v-model="counter.cFamily"
            v-validate="'required|max:20'"
            :class="{ ' border-tex-2': submittedAll && errors.has('family') }"
          />
        </div>

        <span class="mt-2 flex items-center">
          <button
            type="submit"
            class="
              text-white
              border
              h-search
              sm:h-minput
              bg-kbutton-1
              hover:bg-kbutton-2
              active:border active:border-kbutton-3
              px-4
              sm:px-6
              text-sm
              sm:text-base
              rounded
              focus:outline-none
            "
          >
            <LoaderCss class="ml-16" v-show="addRequest" />
            <span v-show="!addRequest">{{ $t("meterTrash.add") }}</span>
          </button>
          <button
            type="button"
            class="
              ml-4
              border
              text-kbutton-1
              border-kbutton-1
              h-search
              sm:h-minput
              hover:border-kbutton-2 hover:text-kbutton-2
              active:border active:border-kbutton-3
              py-1
              sm:py-2
              px-4
              sm:px-6
              text-sm
              sm:text-base
              rounded
              focus:outline-none
            "
            @click="passAddCounter"
          >
            {{ $t("homeDash.cancelBtn") }}
          </button>
        </span>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Iconclose from "../../assets/svg/dashbIcons/close.svg?inline";
import LoaderCss from "@/components/LoaderCss";
import KdCounterSwitch from "../../components/dashboardComponents/KdCounterSwitch.vue";
export default {
  components: {
    Iconclose,
    LoaderCss,
    KdCounterSwitch,
  },

  data() {
    return {
      invalidMeter: false,
      blockedMeter: false,
      valider: false,
      submitted: false,
      submittedAll: false,
      noBills: false,
      counter: {
        cNumb: "",
        cUser: "",
        cFamily: "",
        active: false,
      },
      validRequest: false,
      addRequest: false,
      isPostCounter: true,
    };
  },
  computed: {
    addCounter() {
      return this.$store.getters.getAddCounter;
    },
  },

  methods: {
    toggle(value) {
      this.isPostCounter = value.counterPost;
    },
    meterValidate() {
      this.invalidMeter = false;
      this.submitted = true;
      this.blockedMeter = false;

      if (this.isPostCounter) {
        this.submitted = false;
        if (/^\d{12}[A-Z]{2}$/g.test(this.counter.cNumb)) {
          this.validRequest = true;
          setTimeout(() => {
            this.validRequest = false;
          }, 3000);
          axios
            .get(
              process.env.VUE_APP_API_BASE_URL +
                `/subscribers?reference=${this.counter.cNumb}&local=fr`
            )
            .then((res) => {
              this.validRequest = false;
              const data = res.data;
              if (data.bills.length === 0) {
                this.noBills = true;
              } else {
                this.counter.cUser = data.subscriberName;
                this.valider = !this.valider;
              }
            });
        }
      } else {
        this.$validator.validate().then((valid) => {
          if (valid) {
            this.validRequest = true;
            axios
              .post("/transaction", {
                meterNumb: this.counter.cNumb,
                amount: "1",
              })
              .then((res) => {
                this.validRequest = false;
                const data = res.data;
                const user = data.customerName;
                this.counter.cUser = user;
                this.valider = !this.valider;
              })
              .catch((err) => {
                this.validRequest = false;
                console.log(err.response);
                if (err.response) {
                  const data = err.response.data;
                  console.log("data: ", data);
                  if (data.state == "-10006") {
                    console.log("Invalid ====>");
                    this.invalidMeter = true;
                  } else if (data.locked == "meter blocked") {
                    console.log("blockedMeter ====>");
                    this.blockedMeter = true;
                  }
                }
              });
          }
        });
      }
    },
    passAddCounter() {
      this.$store.commit("mutAddCounter", false);
      this.valider = false;
    },

    addMeter() {
      this.submittedAll = true;
      if (this.isPostCounter) {
        this.addRequest = true;
        const token = this.$store.getters.getAuthUser.token;
        axios.patch(
          process.env.VUE_APP_API_BASE_URL +
            `/subscribers/bind/${this.counter.cNumb}`,
          {
            commonName: this.counter.cFamily,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        ).then(() => {
          this.addRequest = false;
          this.$emit('end');
          this.passAddCounter();
          this.$store.commit("mutPostpaidCounter");
        });
      } else {
        this.$validator.validate().then((valid) => {
          if (valid) {
            this.addRequest = true;
            this.$store.commit("mutCounter", this.counter);
            this.passAddCounter();
            this.addRequest = false;
          }
        });
      }
    },
  },
  watch: {
    addCounter: {
      immediate: true,
      handler(addCounter) {
        if (process.client) {
          if (addCounter) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      },
    },
  },
};
</script>

<style scoped>
.anime {
  animation: modal 400ms ease-out forwards;
}
@keyframes modal {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.15);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.3);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.5);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.75);
  }
}
</style>
<template>
  <div class="select-container cursor-pointer" @click="toggle" ref="selectRef">
    <div class="select-picker">
      <div>
        <div class="font-black">
          <span>{{ currentData.cNumb }}</span>
          <span v-if="currentData.cFamily"> ({{ currentData.cFamily }})</span>
        </div>
        <span class="name">{{ currentData.cUser }}</span>
      </div>
      <Down />
    </div>
    <div class="select-dropdown" v-if="isOpened">
      <div
        v-for="(option, i) in dataList"
        :key="i"
        @click="select(option)"
        class="option-item"
      >
        <div class="bold">
          <span>{{ option.cNumb }}</span>
          <span v-if="option.cFamily"> ({{ option.cFamily }})</span>
        </div>
        <span class="name">{{ option.cUser }}</span>
      </div>
      <button @click="onButtonClick">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import Down from "../assets/svg/dashbIcons/chevrondown.svg?inline";
export default {
  components: {
    Down,
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    buttonText: {
      type: String,
      default: "",
    },
    defaultIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentData: (!this.values || this.values.length === 0) ? {
        cNumb: '',
        cFamily: '',
        cUser: ''
      }: this.values[this.defaultIndex],
      dataList: this.values.filter((_, index) => index !== this.defaultIndex),
      isOpened: false,
    };
  },
  methods: {
    toggle() {
      this.isOpened = !this.isOpened;
    },
    select(option) {
      this.$emit("change", { ...option });
      this.currentData = { ...option };
      this.dataList = this.values.filter((it) => it.id !== option.id);
    },
    onButtonClick() {
        this.$emit('buttonclick');
    }
  },
  mounted() {
    this.$emit('change',this.currentData)
    const { selectRef } = this.$refs;
    document.addEventListener('click', (event) => {
        if (selectRef && !selectRef.contains(event.target)) {
            this.isOpened = false;
        }
    });
  },
};
</script>

<style scoped>
.font-black {
  font-weight: 900;
}

.bold {
  font-weight: 600;
}

.name {
  font-size: 14px;
}

.select-container {
  position: relative;
}

.select-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #f5f6f6;
  border: 1px solid #cccbcb;
}

.select-dropdown {
  position: absolute;
  top: 100%;
  margin-top: 10px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #cccccc;
  width: 100%;
}

.option-item {
  padding: 8px 16px;
}

.option-item:hover {
  background: #e6eefa;
}

button {
  background: #2d7bf7;
  font-weight: 600;
  color: #fff;
  padding: 16px;
  width: 100%;
  border-radius: 4px;
}
</style>